export const useDefaultVariables = () => {
    const { $i18n } = useNuxtApp();
    const { currentCraftUri } = useCraft();

    // console.log({ v: currentCraftUri.value });

    const variables = computed(() => {
        return {
            uri: decodeURI(currentCraftUri.value),
            site: $i18n.localeProperties.value?.craftSiteHandle
        };
    });

    return { variables };
};
